import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { GetItemQuery, InvoiceItem } from '../core/models';
import { InvoiceItemService } from '../services/InvoiceItemService';
import { NotificationContent, Notifications } from '../pages/Notifications';
import { RouterStore } from './RouterStore';
import { InvoiceItemEditType } from '../pages/controlCenter/items/InvoiceItemEditType.enum';
import { RouterPaths } from '../router/RouterPaths';
import { isEqual, isNil } from 'lodash';
import AppStore from './AppStore';

export interface ItemEditQuery {
    id?: string;
    description?: string;
    isSub?: boolean;
    practice?: string;
    // TODO: can be turned on when classification grouping will be available
    // classification: string;
    // add here query parameters to extend edit items feature
}

export interface OpenItemEditParams {
    query: ItemEditQuery;
    newWindow?: boolean;
}

export interface SelectionModel {
    items?: Map<string, InvoiceItem>;
    excluded?: Map<string, InvoiceItem>;
    all?: boolean;
}

export interface PaginationModel {
    scrollId?: string;
    total?: number;
    page?: number;
}

export interface UpdateInvoiceItems {
    items: Partial<InvoiceItem>[];
    runAt?: Date;
    message: NotificationContent;
    name?: string;
    updateExistingItems?: boolean;
}

export interface ColumnState {
    name: string;
    width?: number;
}

export interface GridState {
    widthModel?: Record<number | string, number>;
    pinned?: {
        left?: string[];
        right?: string[];
    };
    groupingModel?: string[];
    visibilityModel?: Record<number | string, boolean | undefined>;
    sortModel?: {
        field: string;
        sort: 'asc' | 'desc' | null | undefined;
    }[];
    orderModel?: Record<number | string, number>;
}

export interface InvoiceItemsState {
    grid?: GridState;
}

@Service()
export class InvoiceItemStore {
    private _isLoadingItems = false;
    private _items?: InvoiceItem[];
    private _selectionModel?: SelectionModel;
    private _pagingModel?: PaginationModel;

    get isLoadingItems(): boolean {
        return this._isLoadingItems;
    }

    get items(): InvoiceItem[] | undefined {
        return this._items && toJS(this._items);
    }

    get selectionModel(): SelectionModel | undefined {
        return toJS(this._selectionModel);
    }

    get pagingModel(): PaginationModel | undefined {
        return toJS(this._pagingModel);
    }

    get allItemsSelected(): Boolean {
        return Boolean(this._selectionModel?.all);
    }

    get selectedItems(): InvoiceItem[] | undefined {
        if (this._selectionModel?.all) {
            return toJS(this._items);
        }

        return this._selectionModel?.items && [...toJS(this._selectionModel?.items)?.values()];
    }

    get columnsState(): GridState | undefined {
        return toJS(this.appStore.userState?.invoiceItems?.grid);
    }

    constructor(private invoiceItemService: InvoiceItemService, private router: RouterStore, private appStore: AppStore) {
        makeAutoObservable(this);
    }

    updateColumnsState = (state: GridState) => {
        const currentState = toJS(this.appStore.userState?.invoiceItems);
        if (isEqual(currentState?.grid, state)) {
            return;
        }

        const invoiceItemsState = { invoiceItems: { ...currentState, grid: state } };
        this.appStore.updateUserState(invoiceItemsState);
    };

    fetchItems = async (query: GetItemQuery = {}): Promise<void> => {
        if (this._isLoadingItems) {
            return;
        }

        try {
            this._isLoadingItems = true;

            const { data, hits, scrollId } = await this.invoiceItemService.getItems(query);

            if (query.scrollId && query.scroll && this._items) {
                this._items = [...this._items, ...data];
            } else {
                this._items = [];
                this._items = data;
                this.updateSelectionModel(undefined);
            }

            this._pagingModel = {
                total: hits?.total,
                page: hits?.page,
                scrollId: scrollId,
            };
        } finally {
            this._isLoadingItems = false;
        }
    };

    async fetchItemsForAutoComplete(query: GetItemQuery = {}): Promise<InvoiceItem[]> {
        query.ids = undefined;
        query.description = undefined;
        query.searchType = query.searchType || undefined;

        const { data } = await this.invoiceItemService.getItems({ ...query, skipChildren: true });
        return data;
    }

    updateSelectionModel = (params?: SelectionModel): void => {
        if (this._selectionModel?.all && params?.items && params.all === undefined) {
            const excluded = new Map<string, InvoiceItem>();

            this._items?.forEach((item) => {
                const { pimsGeneratedId, hospitalDescription, provider } = item;
                const key = `${pimsGeneratedId}-${hospitalDescription}-${provider.siteId}`;

                if (!params.items?.has(key)) {
                    excluded.set(key, item);
                }
            });

            this._selectionModel = {
                all: true,
                items: this._selectionModel.items,
                excluded,
            };
        } else {
            this._selectionModel = {
                all: params?.all,
                items: params?.items,
                excluded: undefined,
            };
        }
    };

    saveItems = async (props: UpdateInvoiceItems): Promise<void> => {
        const { items, runAt = new Date(), message, name = 'Add to Additional Practices', updateExistingItems } = props;

        if (!items.length) {
            return;
        }

        try {
            await this.invoiceItemService.scheduleSave(items, { isUpdatingExistingItem: updateExistingItems, name, runAt });
            Notifications.success(message);
        } catch (error) {
            Notifications.fromException(error);
            throw error;
        }
    };

    openItemEditForm = ({ query, newWindow }: OpenItemEditParams) => {
        const editType = InvoiceItemEditType.Form;
        const searchItems = Object.keys(query).reduce((agg, key) => {
            return !isNil(query[key]) ? [...agg, `${key}=${encodeURIComponent(query[key])}`] : agg;
        }, [] as string[]);

        const path = `${RouterPaths.ControlCenterPages.Items}/${editType}?${searchItems.join('&')}`;

        if (newWindow) {
            const tab = window.open(path, '_blank');
            tab?.focus();
            return;
        }

        this.router.push(path);
    };
}
