import { observer } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { Cmp, UserRoles } from '@enterprise/common';
import { SpotLoading, SpotSearchbar } from '@enterprise/spot';
import { NavLink, Route } from 'react-router-dom';
import { BulkUpdateDetailsPage } from './BulkUpdateDetailsPage';
import { ScheduledItemDetailsPage } from './ScheduledItemDetailsPage';
import moment from 'moment';
import { environment } from '../../../environment/environment';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { ParentTask, TaskEntity } from '../../../core/entity/TaskEntity';
import i18n from '../../../i18n';
import classNames from 'classnames';
import styles from './ActivityPage.module.scss';
import { Trans } from 'react-i18next';
import { ScreenSizeType, useScreenSize, useStores } from '../../../hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { RouterPaths } from '../../../router/RouterPaths';
import { CancelTaskModal, CancelTaskModalResponse } from './cancelTaskModal';
import { GridCellParams, GridColDef, GridRowData, MaterialDataGrid } from '@enterprise/material-data-grid';
import { PermissionLevel } from '../../../core/enums/permissionLevel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { RootRoutes } from '../../../router';

const DEFAULT_PAGE_SIZE = 25;

enum GridColumns {
    Name = 'name',
}

export const ActivityPage = observer(function ActivityPageComponent() {
    const {
        domain: { activityPageStore, usersStore },
        ui: { app },
    } = useStores();

    const { token } = app;
    const { isLoaded, isTaskLoading, parentTasks, gridState = {} } = activityPageStore;
    const { pageSize = DEFAULT_PAGE_SIZE, page = 0 } = gridState;
    const { isLoadingUserAccess, userPracticesAccess = {} } = usersStore;
    const isLoading = !isLoaded || isLoadingUserAccess;
    const [searchTerm, setSearchTerm] = useState('');
    const [cancelItemId, setCancelItemId] = useState<number>();
    const hasAccess =
        token.hasRole(UserRoles.SystemOwner) ||
        token.hasRole(UserRoles.SuperAdmin) ||
        usersStore.userPermissionLevel === PermissionLevel.DecisionMaker;
    const hasAllPracticesAccess = hasAccess && !userPracticesAccess.practices?.length && !userPracticesAccess.regions?.length;
    const { isUpTo } = useScreenSize();
    const rowHeight = isUpTo(ScreenSizeType.lg) ? 100 : 80;

    useEffect(() => {
        void activityPageStore.load();
        void activityPageStore.fetchParentTasks({ page: 0, pageSize });
        void usersStore.fetchUserAccess(token.user.id);

        return () => {
            activityPageStore.clearTasks();
        };
    }, []);

    const onFilterChanged = (value: string) => {
        setSearchTerm(value);
    };

    const cancelTask = (action: CancelTaskModalResponse): void => {
        if (action === CancelTaskModalResponse.Yes && cancelItemId) {
            void activityPageStore.cancelTasks([cancelItemId]);
        }

        setCancelItemId(undefined);
    };

    const rows = useMemo(() => {
        if (!searchTerm) {
            return parentTasks?.tasks || [];
        }

        return (
            parentTasks?.tasks.filter(({ name }: TaskEntity) => {
                return name.toLowerCase().includes(searchTerm.toLowerCase());
            }) || []
        );
    }, [parentTasks, searchTerm]);

    const nameCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;

        return (
            <div className={classNames('spot-typography__text--body', styles.activityRow)} data-automation-id="activity-center">
                {createParentTaskDescription(task)}
            </div>
        );
    };

    const getParentDescription = (
        task: ParentTask,
    ): { description: string; date?: Date; className: string; count?: number; isError?: boolean; status: TaskStatus } | undefined => {
        const { pending = 0, running = 0, queued = 0, error = 0, success = 0, cancel = 0 } = task.childrenStats;
        switch (task.status) {
            case TaskStatus.PENDING:
                return {
                    description: i18n.t('activityPage:pending', 'Pending'),
                    count: pending,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.RUNNING:
                return {
                    description: i18n.t('activityPage:running', 'Running'),
                    count: running + queued,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.SUCCESS:
                return {
                    description: task.childrenStats?.total ? i18n.t('activityPage:success', 'Success') : i18n.t('activityPage:pending', 'Pending'),
                    count: success,
                    date: task.latestAttempt || task.runAt,
                    className: task.childrenStats?.total ? 'spot-pill--positive' : 'spot-pill--warning',
                    status: task.childrenStats?.total ? task.status : TaskStatus.PENDING,
                };
            case TaskStatus.ERROR:
                return {
                    description: i18n.t('activityPage:error', 'Error'),
                    count: error,
                    date: task.latestAttempt || task.runAt,
                    className: 'spot-pill--negative',
                    isError: true,
                    status: task.status,
                };
            case TaskStatus.CANCEL:
                return {
                    description: i18n.t('activityPage:canceled', 'Canceled'),
                    count: cancel,
                    date: task.runAt,
                    className: 'spot-pill--secondary',
                    status: task.status,
                };
        }

        return undefined;
    };

    const createParentTaskDescription = (parentTask: ParentTask) => {
        const { description, date, className, isError, count, status } = getParentDescription(parentTask) || {};
        const runAllFailed = () => {
            activityPageStore.runAllFailedChildTasks(parentTask);
        };

        return (
            <Row className={styles.row}>
                <Col xs={9} lg={5} className={classNames('spot-typography__text--body', styles.rowItem)}>
                    <NavLink
                        className={classNames('spot-link', styles.name)}
                        to={`${RouterPaths.ControlCenterPages.Activity}/bulk/${parentTask.id}`}
                        data-automation-id={'activity-item-name'}
                    >
                        <span>{parentTask.name}</span>
                    </NavLink>
                    <p className={'spot-typography__text--tertiary'} data-automation-id={'activity-total-items'}>
                        {parentTask.childrenStats?.total} items
                    </p>
                </Col>
                <Col xs={3} lg={2} className={classNames('spot-typography__text--body', styles.status)}>
                    <span className={classNames('spot-pill', className, styles.statusPill)} data-automation-id={'activity-item-status'}>
                        {description}
                    </span>
                    <p className={'spot-typography__text--tertiary'} data-automation-id={'activity-item-status-total-items'}>
                        {count} Items
                    </p>
                </Col>
                <Col xs={9} lg={3} className="spot-typography__text--body">
                    <p data-automation-id={'activity-item-date'} className={classNames('spot-typography__font-weight--bold', styles.date)}>
                        {moment(date).format(environment.dateFormat)}
                    </p>
                </Col>
                <Col xs={3} lg={2} className={classNames('spot-typography__text--body', styles.action)}>
                    {isError && hasAccess && hasAllPracticesAccess && (
                        <NavLink to={'#'} className={'spot-link'} onClick={runAllFailed} data-automation-id="activity-run-all-failed">
                            <Trans i18nKey="activityPage:runAllFailed">Run All Failed</Trans>
                        </NavLink>
                    )}
                    {status === TaskStatus.PENDING && hasAllPracticesAccess && (
                        <NavLink
                            to={'#'}
                            className={'spot-link'}
                            onClick={() => setCancelItemId(parentTask.id)}
                            data-automation-id="cancel-bulk-update"
                        >
                            <Trans i18nKey="activityPage:cancel">Cancel</Trans>
                        </NavLink>
                    )}
                </Col>
            </Row>
        );
    };

    const getRowId = (row: GridRowData): string => {
        const { id, type } = row as TaskEntity;
        return `${id}-${type}`;
    };

    const columns: GridColDef[] = [{ field: GridColumns.Name, flex: 1, renderCell: nameCell }];

    const onPageChange = (newPage: number) => {
        activityPageStore.updateGridState({ page: newPage, pageSize });
        void activityPageStore.fetchParentTasks({ page: newPage, pageSize });
    };

    const onPageSizeChange = (newPageSize: number, details: GridCallbackDetails) => {
        activityPageStore.updateGridState({ page, pageSize: newPageSize });
        void activityPageStore.fetchParentTasks({ page, pageSize: newPageSize });
    };

    return (
        <>
            <Cmp.If cond={isLoading}>
                <SpotLoading text="Loading..." />
            </Cmp.If>
            <Cmp.If cond={!isLoading}>
                <RootRoutes>
                    <Route path={`${RouterPaths.ControlCenterPages.Activity}/bulk/:id`} element={<BulkUpdateDetailsPage />} />
                    <Route path={`${RouterPaths.ControlCenterPages.Activity}/scheduled/:id`} element={<ScheduledItemDetailsPage />} />
                </RootRoutes>
                <Container className={classNames('list', styles.activityPage)}>
                    <Row>
                        <Col xs={12} data-automation-id={'activity-search-bar'}>
                            <SpotSearchbar className={styles.searchBar} onSubmit={onFilterChanged} value={searchTerm} onChange={onFilterChanged} />
                        </Col>
                    </Row>

                    <MaterialDataGrid
                        className={styles.grid}
                        loading={isTaskLoading}
                        columns={columns}
                        rows={rows}
                        rowHeight={rowHeight}
                        getRowClassName={() => styles.row}
                        headerHeight={0}
                        getRowId={getRowId}
                        hideFooterPagination={false}
                        hideFooter={false}
                        pagination
                        rowCount={parentTasks?.totalTasks}
                        page={page}
                        pageSize={pageSize}
                        rowsPerPageOptions={[25, 50, 100, 500]}
                        paginationMode="server"
                        onPageSizeChange={onPageSizeChange}
                        onPageChange={onPageChange}
                    />
                </Container>
            </Cmp.If>
            <CancelTaskModal opened={Boolean(cancelItemId)} onChange={cancelTask} />
        </>
    );
});
