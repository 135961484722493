import * as React from 'react';
import * as _ from 'lodash';
import { Container, Col, Row, ListGroup } from 'react-bootstrap';
import { SpotPill, SpotTextInput } from '@enterprise/spot';
import { observer } from 'mobx-react';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { BulkUpdateFlyoverType, ItemsPageStore } from '../../ItemsPageStore';
import { MARKUP_TYPE_UP, PRICE_TYPE_BASE } from '../constants';
import { InvoiceItem, InvoiceItemWithVariantDetail } from '../../../../../core/models';
import { StoresContext } from '../../../../../contexts';
import i18n from 'i18next';
import moment from 'moment';
import { SelectionGridState } from '../ItemAddPracticeBulkUpdate';
import { ItemsGroupByType } from '../../../../../core/models/organization/settings/enum';
import { DeltaUpdateModel } from '../../formType/BulkPriceEditFormType';
import { BulkStatusEditModel } from '../../formType/BulkStatusEditFormType';
import { PatchUpdateModel } from '../../formType/bulkPatchEditFormType';
import { PaginationModel } from '../../../../../store/invoiceItem.store';

interface UpdateSummaryProps {
    type: string;
    data: DeltaUpdateModel | BulkStatusEditModel | PatchUpdateModel;
    setName: any;
    selectionGridState: SelectionGridState;
    selectedItems: InvoiceItemWithVariantDetail[];
    uniqueItems: InvoiceItem[];
    excludedItems?: InvoiceItem[];
    itemsGroupBy: ItemsGroupByType;
    allItemsSelected?: Boolean;
    pagination?: PaginationModel;
}

interface UpdateSummaryState {
    name: string;
}

@observer
export class UpdateSummary extends React.Component<UpdateSummaryProps, UpdateSummaryState> {
    static contextType = StoresContext;
    state = {
        name: this.buildName(),
    };

    context!: React.ContextType<typeof StoresContext>;

    get store(): ItemsPageStore {
        return this.context.domain.itemsPageStore;
    }

    componentDidMount() {
        if (this.props.selectionGridState.updateName) {
            this.props.setName(this.props.selectionGridState.updateName);
            this.setState({ name: this.props.selectionGridState.updateName });
        } else {
            this.props.setName(this.buildName());
            this.props.selectionGridState.updateName = this.buildName();
        }
    }

    get items(): InvoiceItemWithVariantDetail[] {
        return Array.from(this.props.selectedItems);
    }

    get summaryItems(): InvoiceItem[] {
        // To match existing functionality we only want to display items with unique description/id
        const summaryItems = new Map<string, InvoiceItem>();
        this.props.uniqueItems.forEach((item) => {
            const key = `${item.pimsId}-${item.pimsGeneratedId}-${item.hospitalDescription}`;
            if (!summaryItems.has(key)) {
                summaryItems.set(key, item);
            }
        });
        return [...summaryItems.values()];
    }

    get sites(): any[] {
        const result: any[] = [];
        this.items.forEach((item) => {
            const sites = this.props?.selectionGridState?.selectedPractices;
            item.providers.map((provider) => {
                sites?.map((site) => {
                    if (provider.siteId === site.id) {
                        result.push(site.name);
                    }
                });
            });
        });
        return _.uniq(result);
    }

    removeSummaryItemDupes = () => {
        const itemsAtPractices: InvoiceItem[] = [];
        const dedupedItems = this.summaryItems;
        const sites = this.props?.selectionGridState?.selectedPractices;
        const groupBy = this.props.itemsGroupBy === ItemsGroupByType.Id ? 'pimsGeneratedId' : 'hospitalDescription';
        dedupedItems.map((item) => {
            let found = false;
            sites?.map((site) => {
                if (item.provider.siteId === site.id) {
                    found = true;
                }
            });
            if (found) {
                itemsAtPractices.push(item);
            }
        });
        return itemsAtPractices.filter((value, index, self) => index === self.findIndex((t) => t[groupBy] === value[groupBy]));
    };

    render() {
        const { sites } = this;
        const { type, allItemsSelected, pagination, excludedItems } = this.props;

        return (
            <div className="update-summary-container">
                <Container fluid={true}>
                    <Row>
                        <div className={'summaryTitle'}>
                            <div className={'summaryText'} data-automation-id={'bulk-update-summary-text'}>
                                {type === BulkUpdateFlyoverType.Description && this.renderDescriptionSummary()}
                                {type === BulkUpdateFlyoverType.Classification && this.renderClassificationSummary()}
                                {type === BulkUpdateFlyoverType.Status && this.renderStatusSummary()}
                                {type === BulkUpdateFlyoverType.Price && this.renderPriceSummary()}
                            </div>
                        </div>
                    </Row>
                    <Row className="separator">
                        {i18n.t('controlCenter.import.updateName', 'Update Name')}
                        <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                            <SpotTextInput
                                data-automation-id={'bulk-update-name-input-text'}
                                value={this.state.name}
                                id={'updateNameInput'}
                                onChange={(e) => {
                                    this.setState({ name: e.target.value });
                                    this.props.setName(e.target.value);
                                    this.props.selectionGridState.updateName = e.target.value;
                                }}
                            />
                        </div>
                    </Row>
                    <Row className="separator">
                        <Col xs={12}>
                            <CollapsiblePanel
                                expandedContent={allItemsSelected ? null : this.renderItemsList()}
                                hideToggleIcon={Boolean(allItemsSelected)}
                            >
                                <>
                                    <div data-automation-id={'bulk-update-items-count'}>
                                        <SpotPill color="#33bfcc" isSmall={true}>
                                            {allItemsSelected
                                                ? (pagination?.total || 0) - (excludedItems?.length || 0) || this.removeSummaryItemDupes().length
                                                : this.removeSummaryItemDupes().length}
                                        </SpotPill>{' '}
                                        {i18n.t('controlCenter.import.items', 'Items')}
                                    </div>
                                </>
                            </CollapsiblePanel>
                        </Col>
                    </Row>
                    <Row className="separator">
                        <Col xs={12}>
                            <CollapsiblePanel expandedContent={this.renderSitesList()}>
                                <>
                                    <div data-automation-id={'bulk-update-practices-count'}>
                                        <SpotPill color="#33bfcc" isSmall={true}>
                                            {sites.length}
                                        </SpotPill>{' '}
                                        {i18n.t('controlCenter.import.Practices', 'Practices')}
                                    </div>
                                </>
                            </CollapsiblePanel>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    renderItemsList() {
        const removeDupes = this.removeSummaryItemDupes();
        return (
            <ListGroup variant="flush" className="item-list">
                {removeDupes.map((item) => (
                    <ListGroup.Item key={item.pimsGeneratedId}>
                        <div style={{ display: 'inline-block' }}>
                            {item.hospitalDescription}&nbsp;
                            <small>{item.pimsId || item.pimsGeneratedId}</small>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    }

    renderSitesList() {
        const { sites } = this;

        return (
            <ListGroup variant="flush">
                {sites.map((i) => (
                    <ListGroup.Item key={i}>
                        <div style={{ display: 'inline-block' }}>{i}</div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    }

    renderDescriptionSummary() {
        const data = this.props.data as PatchUpdateModel;
        const title = i18n.t('controlCenter.import.setPracticeDescription', 'Set Practice Description');

        return <h6>{`${title} "${data.hospitalDescription}"`}</h6>;
    }

    renderClassificationSummary() {
        const data = this.props.data as PatchUpdateModel;
        const { isAnimanaOnly } = this.store;
        const title = isAnimanaOnly
            ? i18n.t('controlCenter:import.setProductGroup', 'Set Product Group')
            : i18n.t('controlCenter.import.setClassification', 'Set Classification');

        return (
            <h6>
                {`${title} "${data.classification?.name}"`}
                {data.subClassification?.name &&
                    ` ${i18n.t('controlCenter.import.andSubClassification', 'and SubClassification')} "${data.subClassification.name}"`}
            </h6>
        );
    }

    renderStatusSummary() {
        const data = this.props.data as BulkStatusEditModel;
        return (
            <h6>
                {data.isActive === '1'
                    ? i18n.t('controlCenter.import.setStatusActive', 'Set Status Active')
                    : i18n.t('controlCenter.import.setStatusInactive', 'Set Status Inactive')}
            </h6>
        );
    }

    renderPriceSummary() {
        const data = this.props.data as DeltaUpdateModel;
        return (
            <>
                {data.priceType === PRICE_TYPE_BASE
                    ? `${i18n.t('controlCenter.import.basePrice', 'Base Price')} `
                    : `${i18n.t('controlCenter.import.minimumPrice', 'Minimum Price')} `}
                {data.percent ? `${data.percent}% ` : ''}
                {data.minimumAmount ? `$${data.minimumAmount} ` : ''}
                {data.markupType === MARKUP_TYPE_UP
                    ? `${i18n.t('controlCenter.import.markUp', 'MarkUp')}`
                    : `${i18n.t('controlCenter.import.markDown', 'MarkDown')}`}
                {data.isIncludeZero ? ` ${i18n.t('controlCenter.import.includesZero', 'Includes Zero')}` : ''}
            </>
        );
    }

    buildName() {
        const { type } = this.props;
        const date = moment().format('ll');
        let outText = '';

        if (type === BulkUpdateFlyoverType.Description) {
            const data = this.props.data as PatchUpdateModel;
            const title = i18n.t('controlCenter:import.setPracticeDescription', 'Set Practice Description');

            outText = `${title} "${data.hospitalDescription}"`;
        }

        if (type === BulkUpdateFlyoverType.Classification) {
            const data = this.props.data as PatchUpdateModel;
            const { isAnimanaOnly } = this.store;
            const title = isAnimanaOnly
                ? i18n.t('controlCenter:import.setProductGroup', 'Set Product Group')
                : i18n.t('controlCenter.import.setClassification', 'Set Classification');

            outText = `${title} "${data.classification?.name}"`;
            if (data.subClassification?.name) {
                outText += ` ${i18n.t('controlCenter.import.andSubClassification', 'and SubClassification')} "${data.subClassification.name}"`;
            }
        }

        if (type === BulkUpdateFlyoverType.Status) {
            const data = this.props.data as BulkStatusEditModel;
            outText =
                data.isActive === '1'
                    ? i18n.t('controlCenter.import.setStatusActive', 'Set Status Active')
                    : i18n.t('controlCenter.import.setStatusInactive', 'Set Status Inactive');
        }

        if (type === BulkUpdateFlyoverType.Price) {
            const data = this.props.data as DeltaUpdateModel;
            outText =
                (data.priceType === PRICE_TYPE_BASE
                    ? i18n.t('controlCenter.import.basePrice', 'Base Price')
                    : i18n.t('controlCenter.import.minimumPrice', 'Minimum Price')) + ' ';
            outText = outText + (data.percent ? `${data.percent}%` : data.minimumAmount ? `$${data.minimumAmount}` : '');
            outText = `${outText} ${
                data.markupType === MARKUP_TYPE_UP
                    ? i18n.t('controlCenter.import.markUp', 'MarkUp')
                    : i18n.t('controlCenter.import.markDown', 'MarkDown')
            }`;
        }

        return `${date} ${outText}`;
    }

    onItemDeselected = (item) => {
        return () => {
            this.store.selectItem(item);
        };
    };
}
