import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import { BulkUpdateInvoiceItem, GetItemQuery, InvoiceItem, InvoiceItemWithVariantDetail } from '../../../../core/models';
import { BulkPriceEditFormType, DeltaUpdateModel, PriceDeltaUpdateEntity } from '../formType/BulkPriceEditFormType';
import { BulkStatusEditFormType, BulkStatusEditInfo, BulkStatusEditModel } from '../formType/BulkStatusEditFormType';
import { BulkUpdateFlyover, ClassificationEditFormFieldset, PricingEditFormFieldset, StatusEditFormFieldset } from '../bulkUpdates';
import { MARKUP_TYPE_UP, PRICE_TYPE_BASE } from '../bulkUpdates/constants';
import { BulkUpdateFlyoverType, BulkUpdateParams } from '../ItemsPageStore';
import { PracticeInfo } from '../../../practiceConnections/MyOrganizationPageStore';
import { BulkPatchEditFormType, PatchUpdateModel } from '../formType/bulkPatchEditFormType';
import { DescriptionEditFormFieldset } from '../bulkUpdates/fieldsets/descriptionEditFormFieldset';
import { PaginationModel } from '../../../../store/invoiceItem.store';
import { FEATURES } from '../../../../../../../packages/core';

type BulkUpdateModalProps = {
    type?: BulkUpdateFlyoverType;
    selectedItems?: InvoiceItemWithVariantDetail[];
    invoiceItems?: InvoiceItem[];
    excludedItems?: InvoiceItem[];
    allItemsSelected?: Boolean;
    searchQuery?: GetItemQuery;
    onClose: (props: { reason: 'action' | 'cancel' }) => void;
    pagination?: PaginationModel;
};

type CreateBulkUpdateProps = {
    selectedPractices?: PracticeInfo[] | undefined;
    update: PriceDeltaUpdateEntity | BulkStatusEditInfo | PatchUpdateModel;
    type: BulkUpdateFlyoverType;
};

export const BulkUpdateModal = observer(function ItemsPage(props: BulkUpdateModalProps) {
    const {
        ui: { app },
        domain: { itemsPageStore },
    } = useStores();

    const enhancedSearchUiEnabled = app.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI, true);

    const {
        type, //
        selectedItems = [],
        invoiceItems = [],
        onClose,
        allItemsSelected,
        pagination,
        searchQuery,
        excludedItems,
    } = props;
    const { itemsGroupBy } = itemsPageStore;

    const createBulkUpdate = ({ selectedPractices, update, type }: CreateBulkUpdateProps) => {
        if (!selectedPractices) {
            return;
        }

        const selectedPracticesIds = selectedPractices.map(({ id }) => id);
        const included = invoiceItems?.map(({ pimsGeneratedId }) => pimsGeneratedId);
        const exluded = excludedItems?.map(({ pimsGeneratedId }) => pimsGeneratedId);
        const itemsSearchQuery: GetItemQuery = { ...(searchQuery || {}), siteId: selectedPracticesIds };

        const params: BulkUpdateParams = enhancedSearchUiEnabled
            ? {
                  includedItems: !allItemsSelected ? included : undefined,
                  excludedItems: allItemsSelected ? exluded : undefined,
                  update,
                  type,
                  itemsSearchQuery,
                  pagination,
              }
            : {
                  items: invoiceItems.reduce((agg, item) => {
                      if (selectedPracticesIds.includes(item.provider.siteId)) {
                          agg.push({
                              description: item.hospitalDescription,
                              pimsGeneratedId: item.pimsGeneratedId,
                              provider: item.provider,
                          });
                      }

                      return agg;
                  }, [] as BulkUpdateInvoiceItem[]),
                  update,
                  type,
              };

        void itemsPageStore.createBulkUpdate(params);

        onClose({ reason: 'action' });
    };

    return (
        <>
            {(type === BulkUpdateFlyoverType.Description || type === BulkUpdateFlyoverType.Classification) && (
                <BulkUpdateFlyover
                    type={type}
                    fieldset={
                        (type === BulkUpdateFlyoverType.Description && DescriptionEditFormFieldset) ||
                        (type === BulkUpdateFlyoverType.Classification && ClassificationEditFormFieldset) ||
                        undefined
                    }
                    formType={new BulkPatchEditFormType(type)}
                    onSubmit={(params) => createBulkUpdate({ ...params, type })}
                    onClose={() => onClose({ reason: 'cancel' })}
                    selectedItems={selectedItems}
                    excludedItems={excludedItems}
                    uniqueItems={invoiceItems}
                    itemsGroupBy={itemsGroupBy}
                    pagination={pagination}
                    allItemsSelected={allItemsSelected}
                    sites={itemsPageStore.applications?.filter((app) => app.isActive)}
                />
            )}

            {type === BulkUpdateFlyoverType.Price && (
                <BulkUpdateFlyover
                    type={BulkUpdateFlyoverType.Price}
                    fieldset={PricingEditFormFieldset}
                    formType={new BulkPriceEditFormType()}
                    defaultData={
                        {
                            priceType: PRICE_TYPE_BASE,
                            markupType: MARKUP_TYPE_UP,
                        } as DeltaUpdateModel
                    }
                    onSubmit={(params) => createBulkUpdate({ ...params, type: BulkUpdateFlyoverType.Price })}
                    onClose={() => onClose({ reason: 'cancel' })}
                    selectedItems={selectedItems}
                    uniqueItems={invoiceItems}
                    itemsGroupBy={itemsGroupBy}
                    allItemsSelected={allItemsSelected}
                    excludedItems={excludedItems}
                    pagination={pagination}
                    sites={itemsPageStore.applications?.filter((app) => app.isActive)}
                />
            )}

            {type === BulkUpdateFlyoverType.Status && (
                <BulkUpdateFlyover
                    type={BulkUpdateFlyoverType.Status}
                    fieldset={StatusEditFormFieldset}
                    formType={new BulkStatusEditFormType()}
                    defaultData={
                        {
                            isActive: '1',
                        } as BulkStatusEditModel
                    }
                    onSubmit={(params) => createBulkUpdate({ ...params, type: BulkUpdateFlyoverType.Status })}
                    onClose={() => onClose({ reason: 'cancel' })}
                    selectedItems={selectedItems}
                    uniqueItems={invoiceItems}
                    itemsGroupBy={itemsGroupBy}
                    allItemsSelected={allItemsSelected}
                    excludedItems={excludedItems}
                    pagination={pagination}
                    sites={itemsPageStore.applications?.filter((app) => app.isActive)}
                />
            )}
        </>
    );
});
